<template>
  <v-layout wrap v-if="content">
    <v-flex xs12>
      <translate
        type="text"
        v-model="content.BrochureTitle"
        :languages="languages"
        :label="$t('dtouch.brochure.title')"
        @input="handleChangeIsModified"
        style="padding: 0 10px 0 10px;"
      />
    </v-flex>
    <v-flex xs12 style="margin-top: 20px">
      <translate
        type="text"
        v-model="content.BrochureSubtitle"
        :languages="languages"
        :label="$t('dtouch.brochure.subtitle')"
        @input="handleChangeIsModified"
        style="padding: 0 10px 0 10px;"
      />
    </v-flex>
    <!--
    <v-flex xs12 style="margin-top: 20px">
      <translate
        type="textArea"
        v-model="content.ProductDescription"
        :languages="languages"
        :label="$t('dtouch.productService.description')"
        @input="handleChangeIsModified"
        style="padding: 0 10px 0 10px;"
      />
    </v-flex>
    <v-flex xs6>
      <v-text-field
        v-model="content.ProductPrice"
        :label="$t('dtouch.productService.price')"
        outlined
        @input="handleChangeIsModified"
        hide-details
      />
    </v-flex>
    <v-flex xs6 justify-end style="padding-top: 5px;padding-left: 20px;">
      <v-checkbox
        v-model="content.ProductPromotion"
        :label="$t('dtouch.productService.promotion')"
        @change="handleChangeIsModified"
      />
    </v-flex>
    <v-flex xs12 style="margin-top: 20px">
      <translate
        type="text"
        v-model="content.ProductNote"
        :languages="languages"
        :label="$t('dtouch.productService.note')"
        @input="handleChangeIsModified"
        style="padding: 0 10px 0 10px;"
      />
    </v-flex>
    -->
    <v-flex xs12 style="margin-top: 20px">
      <v-expansion-panels focusable>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{ $t('dtouch.brochure.image', locale) }}
            <v-icon v-if="content.BrochureImage" small color="green" style="max-width:30px;">mdi-pencil-circle</v-icon>
          </v-expansion-panel-header>
          <v-expansion-panel-content style="padding: 25px 0 10px 0;">
            <select-image 
              :image="content.BrochureImage"
              :onChange="handleImage"
              :locale="locale"
              :imageWidth="600"
              :imageHeight="600"
              :aspectRatio="1/1"
              :folder="workspaceID"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{$t('dtouch.brochure.location', locale)}}
            <v-icon v-if="content.BrochureWithLocation" small color="green" style="max-width:30px;">mdi-pencil-circle</v-icon>
          </v-expansion-panel-header>
          <v-expansion-panel-content style="padding: 25px 0 10px 0;">
            <v-checkbox
              v-model="content.BrochureWithLocation"
              :value="content.BrochureWithLocation"
              :label="$t('dtouch.brochure.location', locale)"
              @change="handleChangeIsModified"
              style="margin-top: 0;"
            />
            <template v-if="content.BrochureWithLocation">
              <v-layout wrap align-center>
                <v-flex xs12>
                  <v-text-field
                    v-model="content.BrochureMunicipaly"
                    :label="$t('dtouch.brochure.municipaly', locale)"
                    outlined
                    hideDetails
                    @input="handleChangeIsModified"
                  />
                </v-flex>
                <v-flex xs12 style="margin-top: 10px">
                  <v-text-field
                    v-model="content.BrochureAddress"
                    :label="$t('dtouch.brochure.address', locale)"
                    outlined
                    hideDetails
                    @input="handleChangeIsModified"
                  />
                </v-flex>
                <v-flex xs12 style="margin-top: 10px">
                  <v-text-field
                    v-model="content.BrochurePostalCode"
                    :label="$t('dtouch.brochure.postalCode', locale)"
                    outlined
                    hideDetails
                    @input="handleChangeIsModified"
                  />
                </v-flex>
                <v-flex xs6 style="margin-top: 10px; padding-right: 5px;">
                  <v-text-field
                    v-model="content.BrochureLat"
                    :label="$t('dtouch.brochure.lat', locale)"
                    outlined
                    hideDetails
                    @input="handleChangeIsModified"
                  />
                </v-flex>
                <v-flex xs6 style="margin-top: 10px; padding-left: 5px;">
                  <v-text-field
                    v-model="content.BrochureLon"
                    :label="$t('dtouch.brochure.lon', locale)"
                    outlined
                    hideDetails
                    @input="handleChangeIsModified"
                  />
                </v-flex>
              </v-layout>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{$t('dtouch.brochure.contact', locale)}}
            <v-icon v-if="content.BrochureWithContact" small color="green" style="max-width:30px;">mdi-pencil-circle</v-icon>
          </v-expansion-panel-header>
          <v-expansion-panel-content style="padding: 25px 0 10px 0;">
            <v-checkbox
              v-model="content.BrochureWithContact"
              :value="content.BrochureWithContact"
              :label="$t('dtouch.brochure.contact', locale)"
              @change="handleChangeIsModified"
              style="margin-top: 0;"
            />
            <template v-if="content.BrochureWithContact">
              <v-layout wrap align-center>
                <v-flex xs12>
                  <v-text-field
                    v-model="content.BrochurePhone"
                    :label="$t('dtouch.brochure.phone', locale)"
                    outlined
                    hideDetails
                    @input="handleChangeIsModified"
                  />
                </v-flex>
                <v-flex xs12 style="margin-top: 10px">
                  <v-text-field
                    v-model="content.BrochureFax"
                    :label="$t('dtouch.brochure.fax', locale)"
                    outlined
                    hideDetails
                    @input="handleChangeIsModified"
                  />
                </v-flex>
                <v-flex xs12 style="margin-top: 10px">
                  <v-text-field
                    v-model="content.BrochureEmail"
                    :label="$t('dtouch.brochure.email', locale)"
                    outlined
                    hideDetails
                    @input="handleChangeIsModified"
                  />
                </v-flex>
                <v-flex xs12 style="margin-top: 10px">
                  <v-text-field
                    v-model="content.BrochureWeb"
                    :label="$t('dtouch.brochure.web', locale)"
                    outlined
                    hideDetails
                    @input="handleChangeIsModified"
                  />
                  <br />
                </v-flex>
              </v-layout>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{$t('dtouch.brochure.description', locale)}}
            <v-icon v-if="content.BrochureWithDescription" small color="green" style="max-width:30px;">mdi-pencil-circle</v-icon>
          </v-expansion-panel-header>
          <v-expansion-panel-content style="padding: 25px 0 10px 0;">
            <v-checkbox
              v-model="content.BrochureWithDescription"
              :value="content.BrochureWithDescription"
              :label="$t('dtouch.brochure.description', locale)"
              @change="handleChangeIsModified"
              style="margin-top: 0;"
            />
            <template v-if="content.BrochureWithDescription">
              <v-layout wrap align-center>
                <v-flex xs12>
                  <translate
                    type="richText"
                    v-model="content.BrochureDescription"
                    :languages="languages"
                    :label="$t('dtouch.brochure.description', locale)"
                    @input="handleChangeIsModified"
                    style="padding: 0 10px 0 10px;"
                  />
                </v-flex>
                <v-flex xs12 style="margin-top: 10px">
                  <translate
                    type="text"
                    v-model="content.BrochureNote"
                    :languages="languages"
                    :label="$t('dtouch.brochure.note', locale)"
                    @input="handleChangeIsModified"
                    style="padding: 0 10px 0 10px;"
                  />
                </v-flex>
              </v-layout>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            {{$t('dtouch.brochure.facts', locale)}}
            <v-icon v-if="content.BrochureWithFacts" small color="green" style="max-width:30px;">mdi-pencil-circle</v-icon>
          </v-expansion-panel-header>
          <v-expansion-panel-content style="padding: 25px 0 10px 0;">
            <v-checkbox
              v-model="content.BrochureWithFacts"
              :value="content.BrochureWithFacts"
              :label="$t('dtouch.brochure.facts', locale)"
              @change="handleChangeIsModified"
              style="margin-top: 0;"
            />
            <template v-if="content.BrochureWithFacts">
              <v-layout wrap v-if="content.BrochureWithFacts" align-center>
                <v-flex xs12>
                  <translate
                    v-model="content.BrochureHours"
                    :languages="languages"
                    :label="$t('dtouch.brochure.hours', locale)"
                    @input="handleChangeIsModified"
                    style="padding: 0 10px 0 10px;"
                  />
                </v-flex>
                <v-flex xs12 style="margin-top: 10px">
                  <translate
                    v-model="content.BrochurePrice"
                    :languages="languages"
                    :label="$t('dtouch.brochure.price', locale)"
                    @input="handleChangeIsModified"
                    style="padding: 0 10px 0 10px;"
                  />
                </v-flex>
                <v-flex xs12 style="margin-top: 10px">
                  <translate
                    v-model="content.BrochureServices"
                    type="textArea"
                    :languages="languages"
                    :label="$t('dtouch.brochure.services', locale)"
                    @input="handleChangeIsModified"
                    style="padding: 0 10px 0 10px;"
                  />
                </v-flex>
              </v-layout>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-flex>
  </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import Translate from '@/components/Translate'
import SelectImage from '@/components/selectImage/Index'
import utils from '@/services/utils'
export default {
  name: 'Form45Brochure',
  components: {
    Translate,
    SelectImage,
  },
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    onChangeIsModified: {
      type: Function,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    content: null,
    auxIndication: [],
    allergens: null,
  }),
  computed: {        
    ...mapState('app', ['locale']),
    languages () {
      return this.config.Languages
    },
    workspaceID () {
      return utils.getWorkspace('dtouch')
    }
  },
  watch: {
    editedItem () {
      this.prepareContent()
    },
    auxIndication (v) {
      this.editedItem.FoodVegan = v.indexOf(0) >= 0
      this.editedItem.FoodVegetarian = v.indexOf(1) >= 0
      this.editedItem.FoodNew = v.indexOf(2) >= 0
      this.editedItem.FoodRecommended = v.indexOf(3) >= 0

      this.handleChangeIsModified()
    },
  },
  mounted () {
    this.prepareContent()
  },
  methods: {
    getTranslation(v, locale) {
      return utils.getTranslation(v, locale)
    },
    checkJSON (v) {
      return typeof v == 'object' ? v : JSON.parse(v)
    },
    prepareContent () {
      const aux = this.editedItem
      aux.BrochureTitle = this.checkJSON(aux.BrochureTitle)
      aux.BrochureSubtitle = this.checkJSON(aux.BrochureSubtitle)
      aux.BrochureDescription = this.checkJSON(aux.BrochureDescription)
      aux.BrochureNote = this.checkJSON(aux.BrochureNote)
      aux.BrochureHours = this.checkJSON(aux.BrochureHours)
      aux.BrochurePrice = this.checkJSON(aux.BrochurePrice)
      aux.BrochureServices = this.checkJSON(aux.BrochureServices)
      this.content = aux
    },
    handleChangeIsModified () {
      this.onChangeIsModified(this.content) 
    },
    handleImage (v) {
      if (v) {
        this.content.BrochureImage = v.base64
        this.content.newImage = v 
        this.handleChangeIsModified()
      }
    }
  },
}
</script>

